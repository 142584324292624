// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"


Rails.start()
Turbolinks.start()
ActiveStorage.start()


// Import Bootstrap v5
// import "~bootstrap/scss/bootstrap";

//
import "jquery"
import "@popperjs/core"
import "bootstrap"
import "chartkick/chart.js"


// @import "jquery";
// @import "@popperjs/core";
// @import "bootstrap";

//
// // import 'bootstrap/js/src/alert'
// // import 'bootstrap/js/src/button'
// // import 'bootstrap/js/src/carousel'
// import 'bootstrap/js/src/collapse'
// import 'bootstrap/js/src/dropdown'
// // import 'bootstrap/js/src/modal'
// // import 'bootstrap/js/src/popover'
// import 'bootstrap/js/src/scrollspy'
// // import 'bootstrap/js/src/tab'
// // import 'bootstrap/js/src/toast'
// // import 'bootstrap/js/src/tooltip'

console.log('Test Javascript Jimmy 1')

// require("jquery")
// require("@popperjs/core")
// require("bootstrap")

console.log('Test Javascript Jimmy 2')

// document.addEventListener("turbolinks:load", function() {
//     $(function () {
//         $('[data-toggle="tooltip"]').tooltip()
//         $('[data-toggle="popover"]').popover()
//     })
// })


// jquery('#language_switch').on('change', function(){
//   var selected = jquery('#language_switch option:selected').val();
//   window.location.href = "?locale=" + selected;
// });
//
// jquery('.dropdown-toggle').mouseenter( function() {
//   console.log('test')
//   jquery(this).dropdown('toggle');
// })



/**
 * Minified by jsDelivr using Terser v3.14.1.
 * Original file: /npm/js-cookie@2.2.1/src/js.cookie.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
!function(e){var n;if("function"==typeof define&&define.amd&&(define(e),n=!0),"object"==typeof exports&&(module.exports=e(),n=!0),!n){var t=window.Cookies,o=window.Cookies=e();o.noConflict=function(){return window.Cookies=t,o}}}(function(){function e(){for(var e=0,n={};e<arguments.length;e++){var t=arguments[e];for(var o in t)n[o]=t[o]}return n}function n(e){return e.replace(/(%[0-9A-Z]{2})+/g,decodeURIComponent)}return function t(o){function r(){}function i(n,t,i){if("undefined"!=typeof document){"number"==typeof(i=e({path:"/"},r.defaults,i)).expires&&(i.expires=new Date(1*new Date+864e5*i.expires)),i.expires=i.expires?i.expires.toUTCString():"";try{var c=JSON.stringify(t);/^[\{\[]/.test(c)&&(t=c)}catch(e){}t=o.write?o.write(t,n):encodeURIComponent(String(t)).replace(/%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,decodeURIComponent),n=encodeURIComponent(String(n)).replace(/%(23|24|26|2B|5E|60|7C)/g,decodeURIComponent).replace(/[\(\)]/g,escape);var f="";for(var u in i)i[u]&&(f+="; "+u,!0!==i[u]&&(f+="="+i[u].split(";")[0]));return document.cookie=n+"="+t+f}}function c(e,t){if("undefined"!=typeof document){for(var r={},i=document.cookie?document.cookie.split("; "):[],c=0;c<i.length;c++){var f=i[c].split("="),u=f.slice(1).join("=");t||'"'!==u.charAt(0)||(u=u.slice(1,-1));try{var a=n(f[0]);if(u=(o.read||o)(u,a)||n(u),t)try{u=JSON.parse(u)}catch(e){}if(r[a]=u,e===a)break}catch(e){}}return e?r[e]:r}}return r.set=i,r.get=function(e){return c(e,!1)},r.getJSON=function(e){return c(e,!0)},r.remove=function(n,t){i(n,"",e(t,{expires:-1}))},r.defaults={},r.withConverter=t,r}(function(){})});



// Cookies bar
require("custom/main")
